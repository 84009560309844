import React, { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import ENV from "utils/helpers/env";
import SecondaryButton from "../button/SmallRoundedButton";
import OutlinedButton from "../button/OutlinedButton";

import data from "views/landing/data";
const NAV_LINKS = data[0].data.links;

const LandingPageHeader = ({ className = "" }) => {
  const [expanded, setExpanded] = useState(false);

  const navigator = useNavigate();
  const login = () => {
    navigator("/auth/login");
  };
  return (
    <Navbar
      sticky="top"
      key="lg"
      expand="lg"
      expanded={expanded}
      className={`navbar-bg thick-font ${className ?? ""}`}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="p-0">
          <img className="logo" src={ENV.WL_PRIMARY_LOGO} alt="logo" />
        </Navbar.Brand>

        <div className="create-join-sub ms-auto d-block d-lg-none">
          <a className="sub-menu" href="/join">
            Join
          </a>
          <a className="sub-menu" href="/auth/login">
            Login
          </a>
        </div>

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
            <Offcanvas.Title>
              <Link to="/" onClick={() => setExpanded(false)}>
                <img className="logo" src={ENV.WL_PRIMARY_LOGO} alt="logo" />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3">
              {/* show only 4 links, 6 will cause overflow */}
              {NAV_LINKS.left
                .filter((i) => i.label)
                .slice(0, 2)
                .map((link, index) => (
                  <Nav.Link
                    as={Link}
                    key={index}
                    onClick={() => setExpanded(false)}
                    to={link.link.startsWith("#") ? "/" + link.link : link.link}
                    target={link.link.startsWith("#") ? "_self" : "_blank"}
                    rel="noopener noreferrer"
                    className="color-secondary"
                  >
                    {link.label}
                  </Nav.Link>
                ))}
              {NAV_LINKS.right
                .filter((i) => i.label)
                .slice(0, 2)
                .map((link, index) => (
                  <Nav.Link
                    as={Link}
                    key={index}
                    onClick={() => setExpanded(false)}
                    to={link.link.startsWith("#") ? "/" + link.link : link.link}
                    target={link.link.startsWith("#") ? "_self" : "_blank"}
                    rel="noopener noreferrer"
                    className="color-secondary"
                  >
                    {link.label}
                  </Nav.Link>
                ))}
            </Nav>

            <SecondaryButton
              className="d-none d-lg-block px-36 my-auto"
              onClick={login}
              rounded={false}
              variant="sm"
            >
              Sign Up or Login
            </SecondaryButton>
            <div className="button-box">
              {ENV.WL_APP_SHORT_URL ? (
                <OutlinedButton
                  className="w-100 mb-3"
                  variant="sm"
                  onClick={() => window.open(ENV.WL_APP_SHORT_URL, "_blank")}
                >
                  Download {ENV.WL_BUSINESS_NAME} App
                </OutlinedButton>
              ) : null}
              <SecondaryButton
                onClick={login}
                rounded={false}
                variant="sm"
                className="w-100"
              >
                Sign Up or Login
              </SecondaryButton>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default LandingPageHeader;
