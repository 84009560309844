import React from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import MenuDrawer from "views/components/sidebar/MenuDrawer";
import DefaultGallaryHeading from "views/gallary/components/DefaultGallaryHeading";
import FocusedImageComponent from "views/settings/components/focused-image";
import Anonymoushead from "views/anonymous/anonymousHead";
import ENV from "utils/helpers/env";
import useSize from "hooks/useSize";

export const HeaderTemplate = () => {
  const { anonymousUser, images } = useSelector((state) => state.anonymous);
  const { group: _group, user, brandingData } = anonymousUser || {};
  const [params] = useSearchParams();
  const groupId = params.get("groupId");
  const userId = params.get("anonymousUserId");
  const { width } = useSize();

  const group = {
    allPicsCount: images.length,
  };

  const { aboutUs, website_route, coverImage } = brandingData || {};
  const showPortfolio = !!website_route && !!aboutUs && !!coverImage;

  return (
    <>
      {_group?.coverIcon ? (
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          className="overRideHeader header1"
        >
          <Container className="mw-100">
            <Navbar.Brand as={Link} to="/" className="p-0">
              <img src={ENV.WL_WHITE_LOGO} alt="logo" className="logo" />
            </Navbar.Brand>

            <MenuDrawer
              alwaysWhite={true}
              isAnonymous={true}
              avatar={user?.avatar}
              className="ms-auto"
            />
            <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
              <Nav>
                <NavDropdown
                  align="end"
                  title={
                    <div className="header-user bold-font">
                      <div className="header-thumb">
                        <img
                          className="thumbnail-image object-fit-cover"
                          src={
                            userId === groupId
                              ? "/assets/images/icons/user.png"
                              : user?.avatar || "/assets/images/icons/user.png"
                          }
                          alt="user pic"
                        />
                      </div>
                      Anonymous User
                      <img
                        className="menu-arrow"
                        src="/assets/images/icons/arrow-down.png"
                        alt="user pic"
                      />
                    </div>
                  }
                  id="collasible-nav-dropdown"
                >
                  {!ENV.WL_HIDE_HELPDESK && (
                    <NavDropdown.Item
                      as={Link}
                      to="https://www.kwikpic.in/helpdesk"
                      target="_blank"
                    >
                      <img
                        className="thumbnail-image"
                        src="/assets/images/icons/infoq.png"
                        alt="user pic"
                      />
                      Help & Support
                    </NavDropdown.Item>
                  )}
                  {!ENV.WL_HIDE_TUTORIAL && (
                    <NavDropdown.Item
                      href="https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="thumbnail-image"
                        src="/assets/images/icons/youtube-tut.png"
                        alt="tutorial"
                      />
                      Tutorial
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Link} to="/#about">
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/info.png"
                      alt="user pic"
                    />
                    About
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <Anonymoushead />
      )}

      {_group?.coverIcon && (
        <div className="temp-gallary-image temp1-gallary-image">
          {_group?.iconFocalPoint ? (
            width > 576 ? (
              <FocusedImageComponent
                imageSrc={_group.coverIcon}
                x={Number(_group.iconFocalPoint?.split("/")[0]) || 0.0}
                y={Number(_group.iconFocalPoint?.split("/")[1]) || 0.0}
              />
            ) : (
              <FocusedImageComponent
                imageSrc={_group.coverIcon.replace(
                  "cover-icons",
                  "mobile-cover-icons"
                )}
                x={Number(_group.iconFocalPoint?.split("/")[0]) || 0.0}
                y={Number(_group.iconFocalPoint?.split("/")[1]) || 0.0}
                onError={(e) => {
                  e.target.src = _group.coverIcon || _group.icon;
                }}
              />
            )
          ) : null}
          <div
            className="actionArrow"
            onClick={() =>
              document
                .getElementById("gallery-folders-list")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img
              className="banner-image cursor-pointer"
              src="/assets/images/icons/Group-down.png"
            />
          </div>
        </div>
      )}
      <DefaultGallaryHeading
        title={_group?.name}
        group={group}
        branding={brandingData}
        isAnonymous={true}
        showPortfolio={showPortfolio}
        showClickSelfie={groupId === userId}
      />
    </>
  );
};
